function googleAnalytics() {
    !function (e, a, t, n, c, g, o) {
        e.GoogleAnalyticsObject = c, e.ga = e.ga || function () {
            (e.ga.q = e.ga.q || []).push(arguments);
        }, e.ga.l = 1 * new Date, g = a.createElement(t), o = a.getElementsByTagName(t)[0], g.async = 1, g.src = 'https://www.google-analytics.com/analytics.js', o.parentNode.insertBefore(g, o);
    }(window, document, 'script', 0, 'ga');
    ga('create', 'UA-235249271-1', 'auto');
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview');
}
export { googleAnalytics };