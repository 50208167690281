import owlCarousel from 'owl.carousel';

function slider() {
    var owl = jQuery('.slider');
    //var itemCount = jQuery(owl).find('.stage').length;

    owl.owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        navText: ['<img src="/assets/images/arrow-left.png">', '<img src="/assets/images/arrow-right.png">'],
        mouseDrag: false,
        touchDrag: true,
        dots: false,
        items: 3,
        responsive:{
            0:{
                items:1,
            },
            650:{
                items: 1,
            },
            660: { 
                items: 1
            },
            1000:{
                items:2
            },
            1570: {
                items:2,
                margin: 20
            },
            1600: {
                items:3
            }
        }
    });
}

export { slider };
