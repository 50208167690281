import jQuery from 'jquery';
import "@fancyapps/fancybox";
import { lazyload } from './globals/lazyload';
import { googleAnalytics } from './globals/ga';
import { slider } from './blocks/slider';
import { accordion } from './blocks/accordion';
import { navigation } from './blocks/navigation';

jQuery(document).ready(_ => {
    lazyload();
    slider();
    accordion();
    navigation();

    if (typeof Storage != "undefined") {
        if (!localStorage.getItem("ga")) {
          setTimeout(function () {
            $("#cookie-banner").show();
          });
        } else {
          googleAnalytics();
        }
      }
    
      jQuery("a#accepted").click(function () {
        localStorage.setItem("ga", true);
        $("#cookie-banner").hide();
        googleAnalytics();
      });
    
      jQuery("a#decline").click(function () {
        localStorage.setItem("ga", false);
        $("#cookie-banner").hide();
      });
});
